import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { Layout } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import logout from "../../image/logout.png";
import ModalCommon from "../Modal";
import { useLocation } from "react-router-dom";
import { TEXT_DEF } from "../../constant/text";

const { Header } = Layout;

const HeaderCustom = ({ username, collapsed, updateCollapsed }) => {
  const [isShowModal, setIsShowModal] = useState();
  const [titleScreen, setTitleScreen] = useState();
  const location = useLocation();

  useEffect(() => {
    let title;
    if (
      location.pathname.indexOf("/editAccount/") > -1 ||
      location.pathname.indexOf("/createRankByUser/") > -1
    ) {
      location.pathname = "/editAccount/";
    } else if (location.pathname.indexOf("/setPermission/") > -1) {
      location.pathname = "/setPermission";
    }

    switch (location.pathname) {
      case "/account":
        title = TEXT_DEF.manager_account;
        break;
      case "/createAccount":
        title = TEXT_DEF.text0028;
        break;
      case "/growHis":
        title = TEXT_DEF.text0025;
        break;
      case "/warehouseHis":
        title = TEXT_DEF.text0098;
        break;
      case "/settingSys":
        title = TEXT_DEF.text0051;
        break;
      case "/log":
        title = TEXT_DEF.text0116;
        break;
      case "/game":
        title = TEXT_DEF.text0024;
        break;
      case "/notification":
        title = TEXT_DEF.notification;
        break;
      case "/wallet":
        title = TEXT_DEF.text_wallet;
        break;
      case "/check":
        title = TEXT_DEF.amdin_check;
        break;

      default:
        title = "";
        break;
    }
    setTitleScreen(title);
  }, [location]);

  return (
    <>
      <Header className="site-layout-background" style={{ padding: 0 }}>
        <div className="header-cus">
          <div style={{ display: "flex", alignItems: "center" }}>
            {collapsed ? (
              <MenuUnfoldOutlined
                className="trigger menu-icon"
                onClick={updateCollapsed}
              />
            ) : (
              <MenuFoldOutlined
                className="trigger menu-icon"
                onClick={updateCollapsed}
              />
            )}
            <span style={{ fontSize: 18, fontWeight: 700 }}>{titleScreen}</span>
          </div>

          <div className="header-right">
            <span>{username}</span>
            <img
              className="img-logout"
              src={logout}
              alt="logout"
              onClick={() => setIsShowModal(true)}
            />
          </div>
        </div>
      </Header>
      {isShowModal && (
        <ModalCommon
          isShow={isShowModal}
          message={TEXT_DEF.text0022}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0023}
          actionNext={() => {
            setIsShowModal(false);
            localStorage.clear();
            window.location.assign("/login");
          }}
          close={() => setIsShowModal(false)}
        />
      )}
    </>
  );
};

export default HeaderCustom;
