import React, { useEffect, useState } from "react";
import API, { COMMON, subWallet } from "../../network/api";
import moment from "moment";
import { TEXT_DEF } from "../../constant/text";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Pagination,
  Radio,
  Spin,
  Table,
} from "antd";

import { downloadExcel } from "react-export-table-to-excel";
import { HEAD_TABLE_WALLET_sub } from "../../constant/generate";

const ListHis = () => {
  const [form] = Form.useForm();
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");
  const auth = JSON.parse(localStorage.getItem("auth"));

  const [pageTab1, setPageTab1] = useState(1);
  const [dataFilterTab1, setDataFilterTab1] = useState();
  const [totalRecordTab1, setTotalRecordTab1] = useState(0);
  const [sumAvaliableBalance, setSumAvaliableBalance] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [gameCode, setGameCode] = useState();
  const [tab, setTab] = useState();
  const [listGame, setListGame] = useState();
  const [dataHis, setDataHis] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDataGame();
  }, []);

  useEffect(() => {
    getDataHis();
  }, [tab, pageTab1, dataFilterTab1, gameCode]);

  const getDataGame = async () => {
    try {
      const res = await API.get(
        `${COMMON}/all?take=1000&skip=1&order=DESC&keyword=%7B%22module%22%3A%20%22GAME_CODE%22%7D`
      );
      const data = res.data.data.result[0];
      let groupItem = [];
      data.forEach((el) => {
        // const value = el?.item; //gameName
        if (!groupItem.includes(el?.item)) {
          groupItem.push({ code: el?.item, name: el?.detail?.gameName });
        }
      });
      setListGame(groupItem);
      setGameCode(groupItem[0].code);
    } catch (err) {
      console.log(err);
    }
  };


  const getDataHis = async () => {

    try {
      const itemSeach = {
        ...dataFilterTab1,
        gameCode: gameCode,
      };

      const module = itemSeach
        ? encodeURIComponent(JSON.stringify(itemSeach))
        : null;

      const res = await API.get(
        `${subWallet}/all-history?take=10&skip=${pageTab1}&order=DESC&keyword=${module}`
      );
      const data = res.data.data.result[0];
      if (data?.length > 0) {
        let dataCV = [];
        data?.map((item, index) => {
          let itemNew = {
            key: index,
            username: item?.user?.username,
            walletCode: item?.walletCode,
            ft: item?.ft,
            description: item?.description,
            subWalletCode: item?.subWalletCode,
            gameCode: item?.gameCode,
            availableBalance: +item?.availableBalance,
            totalUsedAmount: +item?.totalUsedAmount,
            createdAt: item.createdAt
              ? moment(item?.createdAt)
                .utcOffset(7)
                .format("DD-MM-YYYY HH:mm:ss")
              : null,
            updatedAt: item.updatedAt
              ? moment(item?.updatedAt)
                .utcOffset(7)
                .format("DD-MM-YYYY HH:mm:ss")
              : null,
            createdBy: item?.createdBy,
          };
          dataCV.push(itemNew);
        });
        setDataHis(dataCV);
        setTotalRecordTab1(res.data.data.result[1]);
      } else {
        setDataHis([]);
        setTotalRecordTab1();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const exportDataTab1 = async () => {
    const itemSeach = {
      dataFilterTab1,
      gameCode: gameCode,
    };
    const module = itemSeach
      ? encodeURIComponent(JSON.stringify(itemSeach))
      : null;

    const res = await API.get(
      `${subWallet}/all-history?take=1000000&skip=${pageTab1}&order=DESC&keyword=${module}`
    );
    const data = res.data.data.result[0];
    if (data?.length > 0) {
      let dataCV = [];
      data?.map((item, index) => {
        let itemNew = {
          username: item?.user?.username,
          createdAt: item.createdAt
            ? moment(item?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          updatedAt: item.updatedAt
            ? moment(item?.updatedAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          walletCode: item?.walletCode,
          ft: item?.ft,
          description: item?.description,
          subWalletCode: item?.subWalletCode,
          gameCode: item?.gameCode,
          availableBalance: +item?.availableBalance,
          totalUsedAmount: +item?.totalUsedAmount,
          createdBy: item?.createdBy,
        };
        dataCV.push(itemNew);
      });
      setDataHis(dataCV);
      setTotalRecordTab1(res.data.data.result[1]);
      const header = [
        TEXT_DEF.text0002,
        TEXT_DEF.dayBy,
        TEXT_DEF.updatedAt,
        TEXT_DEF.code_wallet,
        TEXT_DEF.ft,
        "Mô tả",
        TEXT_DEF.code_wallet_sub,
        TEXT_DEF.game,
        TEXT_DEF.sub_wallet_number,
        TEXT_DEF.totalUsedAmount,
        TEXT_DEF.user_admin,
      ];

      downloadExcel({
        fileName: "Lịch sử ví con",
        sheet: "Sheet 1",
        tablePayload: {
          header: header,
          body: dataCV,
        },
      });
    }
  };

  const onChangePage = (page) => {
    setPageTab1(page);
  };

  const onSearch = () => {
    let params = {};

    if (form.getFieldValue("usernameFilter")) {
      params.username = form
        .getFieldValue("usernameFilter")
        .trim()
        .toLowerCase();
    }

    if (dateFrom) {
      params.startDate = moment(dateFrom).format("YYYY-MM-DD");
      if (!dateTo) params.endDate = "2125-12-31";
    }
    if (dateTo) {
      params.endDate = moment(dateTo).format("YYYY-MM-DD");
      if (!dateFrom) params.endDate = "2023-01-01";
    }

    setDataFilterTab1(params ? params : undefined);
    setPageTab1(1);
  };

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).startOf("day").format('yyyy-MM-DD'));
      else setDateTo(moment(e.$d).endOf("day").format('yyyy-MM-DD'));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
  };
  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const rowClassName = (record) => {
    return (
      record.isBlock || (record.isBlockTransfer && "opacity-50 bg-black/10")
    );
  };

  const onChangeTab = (e) => {
    setTab(e.target.value);
    setGameCode(e.target.value);
    setPageTab1(1);
    setDateFrom(null);
    setDateTo(null);
    form.setFieldValue("usernameFilter", undefined);
    form.setFieldValue("dateFrom", undefined);
    form.setFieldValue("dateTo", undefined);
  };
  return (
    <div>
      <div
        className="col-12"
        style={{
          // borderBottom: "1px solid #CCC",
          // paddingBottom: "2rem",
          marginBottom: "1rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="text-center font-bold text-lg mb-2">Danh sách Mini Game: </div>
        <Radio.Group className="flex justify-center items-center mb-4" size="large" buttonStyle="solid" onChange={onChangeTab}>
          {auth?.role === "supper" ||
            (auth?.role === "admin" &&
              roleArr.some(
                (el) => el === "sub_wallet_view" || el === "sub_wallet_update"
              ))
            ? listGame &&
            listGame.map((item, index) => (
              <Radio.Button className="font-semibold" key={index} value={item?.code}>
                {item.name}
              </Radio.Button>
            ))
            : null}
        </Radio.Group>
      </div>
      <div className="col-12">
        <Form
          form={form}
          layout="inline"
          autoComplete="off"
          onFinish={() => onSearch()}
        >
          <div className="col-12" style={{ marginBottom: 15, display: "flex" }}>
            <div className="col-4">
              <Form.Item name="usernameFilter" label={TEXT_DEF.text0002}>
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </div>
            <div className="col-4">
              <Form.Item name="dateFrom" label={TEXT_DEF.text0032}>
                <DatePicker
                  placeholder=""
                  onChange={(e) => onChangeDate(e, "from")}
                  format={"DD-MM-YYYY"}
                  style={{ width: "100%" }}
                  disabledTime={(d) =>
                    !d || d.isSameOrAfter(dateTo && moment(dateTo).add(1, "d"))
                  }
                />
              </Form.Item>
            </div>
            <div className="col-4">
              <Form.Item name="dateTo" label={TEXT_DEF.text0033}>
                <DatePicker
                  placeholder=""
                  onChange={(e) => onChangeDate(e, "to")}
                  format={"DD-MM-YYYY"}
                  style={{ width: "100%" }}
                  disabledTime={(d) =>
                    !d || d.isSameOrBefore(dateFrom ? dateFrom : null)
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="payment-search">
            <Button htmlType="submit" type="primary">
              {TEXT_DEF.text0031}
            </Button>
            <Button
              style={{ marginLeft: 20 }}
              onClick={exportDataTab1}
              htmlType="button"
              type="primary"
            >
              {TEXT_DEF.text0050}
            </Button>
          </div>
        </Form>
      </div>
      <Spin spinning={loading} delay={500} size="large">
        <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
          <Table
            rowClassName={rowClassName}
            key="dataHis"
            columns={HEAD_TABLE_WALLET_sub}
            dataSource={dataHis}
            pagination={false}
            bordered
            style={{ width: "100%" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 15,
            justifyContent: "flex-end",
          }}
        >
          <Pagination
            current={pageTab1}
            total={totalRecordTab1}
            onChange={onChangePage}
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={showTotal}
          />
        </div>
      </Spin>
    </div>
  );
};

export default ListHis;
