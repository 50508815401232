import React, { useState } from "react";
import { Menu } from "antd";
import {
  IdcardFilled,
  FilterFilled,
  SettingFilled,
  NotificationOutlined,
  AndroidOutlined,
  WalletOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import { TEXT_DEF } from "../../constant/text";
import {
  MANAGER_PESMISSION,
  SETTING_HIS,
  SETTING_SYTEMS,
} from "../../constant/generate";

const SideBarCustom = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  let location = useLocation();
  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/account"
      : location.pathname
  );

  const handleClick = (e) => {
    setCurrent(e.key);
  };

  return (
    <>
      <div className="logo font-extrabold flex text-center">Hậu đài Trung tâm Game 8DAY</div>

      <Menu onClick={handleClick} mode="inline" selectedKeys={[current]}>
        {auth?.role === "supper" ||
          (auth?.role === "admin" &&
            (roleArr?.indexOf("user_view") > -1 ||
              roleArr?.indexOf("user_edit") > -1)) ? (
          <Menu.Item
            style={{ padding: "0 16px" }}
            key="/account"
            icon={<IdcardFilled className="menu-icon" />}
          >
            <Link to="/account">{TEXT_DEF.manager_account}</Link>
          </Menu.Item>
        ) : null}
        {auth?.role === "supper" ||
          (auth?.role === "admin" &&
            roleArr.some((el) => el === "transaction_view")) ? (
          <Menu.Item
            style={{ padding: "0 16px" }}
            key="/growHis"
            icon={<FilterFilled className="menu-icon" />}
          >
            <Link to="/growHis">{TEXT_DEF.text0123}</Link>
          </Menu.Item>
        ) : null}

        {auth?.role === "supper" ||
          (auth?.role === "admin" &&
            roleArr.some(
              (el) => el === "system_config_view" || el === "system_config_edit"
            )) ? (
          <Menu.Item
            style={{ padding: "0 16px" }}
            key="/settingSys"
            icon={<SettingFilled className="menu-icon" />}
          >
            <Link to="/settingSys">{TEXT_DEF.text0051}</Link>
          </Menu.Item>
        ) : null}

        {auth?.role === "supper" ||
          (auth.role === "admin" &&
            roleArr.some(
              (el) => el === "system_config_view" || el === "system_config_edit"
            )) ? (
          <Menu.Item
            style={{ padding: "0 16px" }}
            key="/notification"
            icon={<NotificationOutlined className="menu-icon" />}
          >
            <Link key="/notification" to="/notification">
              {TEXT_DEF.notification}
            </Link>
          </Menu.Item>
        ) : null}

        {auth?.role === "supper" ||
          (auth.role === "admin" &&
            roleArr.some(
              (el) => el === "system_config_view" || el === "system_config_edit"
            )) ? (
          <Menu.Item
            style={{ padding: "0 16px" }}
            key="/game"
            icon={<AndroidOutlined className="menu-icon" />}
          >
            <Link key="/game" to="/game">
              {TEXT_DEF.text0024}
            </Link>
          </Menu.Item>
        ) : null}

        {auth?.role === "supper" ||
          (auth.role === "admin" &&
            MANAGER_PESMISSION.some((el) => roleArr.includes(el))) ? (
          <Menu.Item
            style={{ padding: "0 16px" }}
            key="/wallet"
            icon={<WalletOutlined className="menu-icon" />}
          >
            <Link key="/wallet" to="/wallet">
              {TEXT_DEF.text_wallet}
            </Link>
          </Menu.Item>
        ) : null}
        {auth?.role === "supper" ||
          (auth.role === "admin" &&
            roleArr.some(
              (el) => el === "sub_wallet_view" || el === "sub_wallet_update"
            )) ? (
          <Menu.Item
            style={{ padding: "0 16px" }}
            key="/sub-wallet"
            icon={<WalletOutlined className="menu-icon" />}
          >
            <Link key="/sub-wallet" to="/sub-wallet">
              {TEXT_DEF.text_wallet_sub}
            </Link>
          </Menu.Item>
        ) : null}
        {/* {auth?.role === "supper" ||
          (auth.role === "admin" &&
            roleArr.some(
              (el) => el === "sub_wallet_view" || el === "sub_wallet_update"
            )) ? (
          <Menu.Item
            style={{ padding: "0 16px" }}
            key="/his-sub-wallet"
            icon={<WalletOutlined className="menu-icon" />}
          >
            <Link key="/his-sub-wallet" to="/his-sub-wallet">
              {TEXT_DEF.text_wallet_sub_his}
            </Link>
          </Menu.Item>
        ) : null} */}
        {auth?.role === "supper" ||
          (auth.role === "admin" &&
            roleArr.some((el) => el === "approve_top_up")) ? (
          <Menu.Item
            style={{ padding: "0 16px" }}
            key="/check"
            icon={<UserOutlined className="menu-icon" />}
          >
            <Link key="/check" to="/check">
              {TEXT_DEF.amdin_check}
            </Link>
          </Menu.Item>
        ) : null}
      </Menu>
    </>
  );
};

export default SideBarCustom;
