import { Radio } from "antd";
import React, { useState } from "react";
import { TEXT_DEF } from "../../constant/text";
import ListRequest from "./list-request";

const AdminCheck = () => {
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");
  const auth = JSON.parse(localStorage.getItem("auth"));

  const [indexTab, setIndexTab] = useState(0);
  const onChangeTab = (e) => {
    setIndexTab(e.target.value);
  };

  return (
    <div className="col-12">
      <div
        className="col-12"
        style={{
          borderBottom: "1px solid #CCC",
          paddingBottom: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Radio.Group onChange={onChangeTab}>
          <Radio.Button value={0}>{TEXT_DEF.list_request}</Radio.Button>
        </Radio.Group>
      </div>
      {indexTab === 0 ? <ListRequest /> : null}
    </div>
  );
};

export default AdminCheck;
