import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Pagination,
  Radio,
  Spin,
  Table,
} from "antd";
import moment from "moment";
import { downloadExcel } from "react-export-table-to-excel";
import API, { COMMON, subWallet } from "../../network/api";
import { TEXT_DEF } from "../../constant/text";
import ModalCommon from "../../components/Modal";
import { HEAD_TABLE_WALLET_sub } from "../../constant/generate";
import ListHis from "../list-his-sub";

const SubWallet = () => {
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  const auth = JSON.parse(localStorage.getItem("auth"));
  const [form] = Form.useForm();

  const [pageTab1, setPageTab1] = useState(1);
  const [dataFilterTab1, setDataFilterTab1] = useState();
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecordTab1, setTotalRecordTab1] = useState(0);
  const [sumAvaliableBalance, setSumAvaliableBalance] = useState();
  const [tab, setTab] = useState("");
  const [gameCode, setGameCode] = useState();

  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [itemRecord, setItemRecord] = useState();
  const [key, setKey] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [listGame, setListGame] = useState();
  const [dataHis, setDataHis] = useState();

  useEffect(() => {
    getDataTab1();
  }, [tab, pageTab1, dataFilterTab1]);

  useEffect(() => {
    getDataGame();
  }, []);

  const getDataGame = async () => {
    try {
      const res = await API.get(
        `${COMMON}/all?take=100&skip=1&order=DESC&keyword=%7B%22module%22%3A%20%22GAME_CODE%22%7D`
      );
      const data = res.data.data.result[0];
      let groupItem = [];
      data.forEach((el) => {
        const value = el.item;
        if (!groupItem.includes(value)) {
          groupItem.push(value);
        }
      });
      setListGame(groupItem);
    } catch (err) {
      console.log(err);
    }
  };

  const getDataHis = async () => {
    try {
      const itemSeach = {
        dataFilterTab1,
        gameCode: gameCode,
      };
      const module = itemSeach
        ? encodeURIComponent(JSON.stringify(itemSeach))
        : null;

      const res = await API.get(
        `${subWallet}/all-history?take=10&skip=${pageTab1}&order=DESC&keyword=${module}`
      );
      const data = res.data.data.result[0];
      if (data?.length > 0) {
        let dataCV = [];
        data?.map((item, index) => {
          let itemNew = {
            key: index,
            username: item?.user?.username,
            walletCode: item?.walletCode,
            ft: item?.ft,
            description: item?.description,
            subWalletCode: item?.subWalletCode,
            gameCode: item?.gameCode,
            availableBalance: +item?.availableBalance,
            totalUsedAmount: +item?.totalUsedAmount,
            createdAt: item.createdAt
              ? moment(item?.createdAt)
                .utcOffset(7)
                .format("DD-MM-YYYY HH:mm:ss")
              : null,
            updatedAt: item.updatedAt
              ? moment(item?.updatedAt)
                .utcOffset(7)
                .format("DD-MM-YYYY HH:mm:ss")
              : null,
            createdBy: item?.createdBy,
          };
          dataCV.push(itemNew);
        });
        setDataHis(dataCV);
        setTotalRecordTab1(res.data.data.result[1]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDataTab1 = async () => {
    setLoading(true);
    let kw = dataFilterTab1
      ? encodeURIComponent(JSON.stringify(dataFilterTab1))
      : null;

    let URL = `${subWallet}/all?take=10&skip=${pageTab1}&order=DESC&keyword=${kw}`;

    const listData = await API.get(URL);

    const result = listData.data.data.result.subWallets[0];

    setSumAvaliableBalance(
      Number(listData?.data?.data?.result.sumAvaliableBalance.sum)
    );

    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          key: item?.id,
          username: item.user.username,
          subWalletCode: item?.subWalletCode,
          walletCode: item?.walletCode,
          gameCode: item?.gameCode,
          availableBalance: +item?.availableBalance,
          holdBalance: +item?.holdBalance,
          totalUsedAmount: +item?.totalUsedAmount,
          isBlock: item.isBlock,
          isBlockTransfer: item.isBlockTransfer,
          totalAvailableBalance: +item?.totalAvailableBalance,
          createdAt: item.createdAt
            ? moment(item?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          createdBy: item?.createdBy,
        };

        dataCV.push(itemNew);
      });
      setDataList(dataCV);
      setTotalRecordTab1(listData.data.data.result.subWallets[1]);
      if (tab !== "") {
        // const Modul
        const itemSeach = {
          dataFilterTab1,
          gameCode: gameCode,
        };
        const module = itemSeach
          ? encodeURIComponent(JSON.stringify(itemSeach))
          : null;

        const res = await API.get(
          `${subWallet}/all-history?take=10&skip=${pageTab1}&order=DESC&keyword=${module}`
        );
        const data = res.data.data.result[0];
        if (data?.length > 0) {
          let dataCV = [];
          data?.map((item, index) => {
            let itemNew = {
              key: index,
              username: item?.user?.username,
              walletCode: item?.walletCode,
              ft: item?.ft,
              description: item?.description,
              subWalletCode: item?.subWalletCode,
              gameCode: item?.gameCode,
              availableBalance: +item?.availableBalance,
              totalUsedAmount: +item?.totalUsedAmount,
              createdAt: item.createdAt
                ? moment(item?.createdAt)
                  .utcOffset(7)
                  .format("DD-MM-YYYY HH:mm:ss")
                : null,
              updatedAt: item.updatedAt
                ? moment(item?.updatedAt)
                  .utcOffset(7)
                  .format("DD-MM-YYYY HH:mm:ss")
                : null,
              createdBy: item?.createdBy,
            };
            dataCV.push(itemNew);
          });
          setDataHis(dataCV);
          setTotalRecordTab1(res.data.data.result[1]);
        }
      }
    } else {
      setDataList([]);
    }
    setLoading(false);
  };

  const exportDataTab1 = async () => {
    setLoading(true);
    let kw = dataFilterTab1
      ? encodeURIComponent(JSON.stringify(dataFilterTab1))
      : null;

    let URL = `${subWallet}/all?take=10000&skip=${pageTab1}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);

    const result = listData.data.data.result.subWallets[0];

    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          username: item.user?.username,
          subWalletCode: item?.subWalletCode,
          walletCode: item?.walletCode,
          gameCode: item?.gameCode,
          availableBalance: +item?.availableBalance,
          totalUsedAmount: +item?.totalUsedAmount,
          createdAt: item.createdAt
            ? moment(item?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          createdBy: item?.createdBy,
        };
        dataCV.push(itemNew);
      });
      const header = [
        TEXT_DEF.text0002,
        TEXT_DEF.code_wallet_sub,
        TEXT_DEF.code_wallet,
        "Game",
        TEXT_DEF.sub_wallet_number,
        TEXT_DEF.totalUsedAmount,
        TEXT_DEF.dayBy,
        TEXT_DEF.createdBy,
      ];

      downloadExcel({
        fileName: "Danh sách ví con",
        sheet: "sheet 1",
        tablePayload: {
          header: header,
          body: dataCV,
        },
      });
      if (tab !== "") {
        const itemSeach = {
          dataFilterTab1,
          gameCode: gameCode,
        };
        const module = itemSeach
          ? encodeURIComponent(JSON.stringify(itemSeach))
          : null;

        const res = await API.get(
          `${subWallet}/all-history?take=10&skip=${pageTab1}&order=DESC&keyword=${module}`
        );
        const data = res.data.data.result[0];
        if (result?.length > 0) {
          let dataCV = [];
          data?.map((item, index) => {
            let itemNew = {
              username: item?.user?.username,
              walletCode: item?.walletCode,
              ft: item?.ft,
              description: item?.description,
              subWalletCode: item?.subWalletCode,
              gameCode: item?.gameCode,
              balance: +item?.balance,
              availableBalance: +item?.availableBalance,
              totalUsedAmount: +item?.totalUsedAmount,
              createdAt: item.createdAt
                ? moment(item?.createdAt)
                  .utcOffset(7)
                  .format("DD-MM-YYYY HH:mm:ss")
                : null,
              updatedAt: item.updatedAt
                ? moment(item?.updatedAt)
                  .utcOffset(7)
                  .format("DD-MM-YYYY HH:mm:ss")
                : null,
              createdBy: item?.createdBy,
            };
            dataCV.push(itemNew);
          });
          setDataHis(dataCV);
          setTotalRecordTab1(res.data.data.result[1]);
          const header = [
            TEXT_DEF.text0002,
            TEXT_DEF.dayBy,
            TEXT_DEF.updatedAt,
            TEXT_DEF.code_wallet,
            TEXT_DEF.ft,
            "Mô tả",
            TEXT_DEF.code_wallet_sub,
            TEXT_DEF.game,
            TEXT_DEF.sub_wallet_number,
            TEXT_DEF.totalUsedAmount,
            TEXT_DEF.user_admin,
          ];

          downloadExcel({
            fileName: "Lịch sử ví con",
            sheet: "Sheet 1",
            tablePayload: {
              header: header,
              body: dataCV,
            },
          });
        }
      }
    }

    setLoading(false);
  };

  const onChangePage = (page) => {
    setPageTab1(page);
  };

  const onSearch = () => {
    let params = {};

    if (form.getFieldValue("usernameFilter")) {
      params.username = form
        .getFieldValue("usernameFilter")
        .trim()
        .toLowerCase();
    }

    if (dateFrom) {
      params.startDate = moment(dateFrom).format("YYYY-MM-DD");
      if (!dateTo) params.endDate = "2125-12-31";
    }
    if (dateTo) {
      params.endDate = moment(dateTo).format("YYYY-MM-DD");
      if (!dateFrom) params.endDate = "2023-01-01";
    }

    setDataFilterTab1(params ? params : undefined);
    setPageTab1(1);
  };

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).startOf("day").format('yyyy-MM-DD'));
      else setDateTo(moment(e.$d).endOf("day").format('yyyy-MM-DD'));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
  };
  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const handleShowModal = (item, key) => {
    setIsShowModalConfirm(true);
    setItemRecord(item);
    setKey(key);
  };

  const handleBlockAndUnBlock = async () => {
    try {
      setIsShowModalSuccess(true);
      const body = {
        isBlock: key === "block" ? true : false,
      };
      await API.patch(`${subWallet}/${itemRecord.key}`, body);
      await getDataTab1();
    } catch (err) {
      console.log(err);
    } finally {
      setIsShowModalConfirm(false);
    }
  };

  const handleBlockTranfer = async () => {

    try {
      setIsShowModalSuccess(true);
      const body = {
        isBlockTransfer: key === "blockTransfer" ? false : true,
      };
      await API.patch(`${subWallet}/${itemRecord.key}`, body);
      await getDataTab1();
    } catch (err) {
      console.log(err);
    } finally {
      setIsShowModalConfirm(false);
    }
  };

  const head_table = [
    {
      title: TEXT_DEF.text0002,
      key: "username",
      dataIndex: "username",
    },
    {
      title: TEXT_DEF.code_wallet_sub,
      key: "subWalletCode",
      dataIndex: "subWalletCode",
    },
    {
      title: TEXT_DEF.code_wallet,
      key: "walletCode",
      dataIndex: "walletCode",
    },
    {
      title: "Game",
      key: "gameCode",
      dataIndex: "gameCode",
    },

    {
      title: TEXT_DEF.sub_wallet_number,
      key: "availableBalance",
      dataIndex: "availableBalance",
    },
    {
      title: TEXT_DEF.totalUsedAmount,
      key: "totalUsedAmount",
      dataIndex: "totalUsedAmount",
    },
    {
      title: TEXT_DEF.dayBy,
      key: "createdAt",
      dataIndex: "createdAt",
    },
    {
      title: TEXT_DEF.createdBy,
      key: "createdBy",
      dataIndex: "createdBy",
    },
  ];
  const rowClassName = (record) => {
    return (
      record.isBlock || (record.isBlockTransfer && "opacity-50 bg-black/10")
    );
  };

  const onChangeTab = (e) => {
    setTab(e.target.value);
    setGameCode(e.target.value);
    setPageTab1(1);
    setDateFrom(null);
    setDateTo(null);
    form.setFieldValue("usernameFilter", undefined);
    form.setFieldValue("dateFrom", undefined);
    form.setFieldValue("dateTo", undefined);
  };

  return (
    <>
      <div
        className="col-12"
        style={{
          borderBottom: "1px solid #CCC",
          paddingBottom: "2rem",
          marginBottom: "1rem",
        }}
      >
        <Radio.Group onChange={onChangeTab}>
          {auth?.role === "supper" ||
            (auth?.role === "admin" &&
              roleArr.some((el) => el === "sub_wallet_view")) ? (
            <>
              <Radio.Button value={""}>{TEXT_DEF.sub_wallet}</Radio.Button>
              <Radio.Button value={2}>{TEXT_DEF.sub_wallet_his}</Radio.Button>
            </>
          ) : null}
        </Radio.Group>
      </div>
      {tab !== 2 ? (
        <>
          <div className="col-12">
            <Form
              form={form}
              layout="inline"
              autoComplete="off"
              onFinish={() => onSearch()}
            >
              <div className="col-12" style={{ marginBottom: 15, display: "flex" }}>
                <div className="col-4">
                  <Form.Item name="usernameFilter" label={TEXT_DEF.text0002}>
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                </div>
                <div className="col-4">
                  <Form.Item name="dateFrom" label={TEXT_DEF.text0032}>
                    <DatePicker
                      placeholder=""
                      onChange={(e) => onChangeDate(e, "from")}
                      format={"DD-MM-YYYY"}
                      style={{ width: "100%" }}
                      disabledTime={(d) =>
                        !d || d.isSameOrAfter(dateTo && moment(dateTo).add(1, "d"))
                      }
                    />
                  </Form.Item>
                </div>
                <div className="col-4">
                  <Form.Item name="dateTo" label={TEXT_DEF.text0033}>
                    <DatePicker
                      placeholder=""
                      onChange={(e) => onChangeDate(e, "to")}
                      format={"DD-MM-YYYY"}
                      style={{ width: "100%" }}
                      disabledTime={(d) =>
                        !d || d.isSameOrBefore(dateFrom ? dateFrom : null)
                      }
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="payment-search">
                <Button htmlType="submit" type="primary">
                  {TEXT_DEF.text0031}
                </Button>
                <Button
                  style={{ marginLeft: 20 }}
                  onClick={exportDataTab1}
                  htmlType="button"
                  type="primary"
                >
                  {TEXT_DEF.text0050}
                </Button>
              </div>
            </Form>
          </div>
          <div className="flex mt-4 items-center">
            <div>{TEXT_DEF.sumAvaliableBalance}:</div>
            <div className="font-bold ml-4">{sumAvaliableBalance}</div>
          </div>
          <Spin spinning={loading} delay={500} size="large">
            <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
              <Table
                rowClassName={rowClassName}
                key="dataHis"
                columns={tab === "" ? head_table : HEAD_TABLE_WALLET_sub}
                dataSource={tab === "" ? dataList : dataHis}
                pagination={false}
                bordered
                style={{ width: "100%" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                marginTop: 15,
                justifyContent: "flex-end",
              }}
            >
              <Pagination
                current={pageTab1}
                total={totalRecordTab1}
                onChange={onChangePage}
                defaultPageSize={10}
                showSizeChanger={false}
                showTotal={showTotal}
              />
            </div>
          </Spin>
          {isShowModalConfirm && (
            <ModalCommon
              isShow={isShowModalConfirm}
              message={TEXT_DEF.text_modal_confirm}
              txtBtnAcc={TEXT_DEF.text0008}
              textBtnClose={TEXT_DEF.text0023}
              actionNext={
                key === "block" || key === "unblock"
                  ? handleBlockAndUnBlock
                  : handleBlockTranfer
              }
              close={() => setIsShowModalConfirm(false)}
            />
          )}
          {isShowModalSuccess && (
            <ModalCommon
              isShow={isShowModalSuccess}
              message={TEXT_DEF.text_modal_success}
              txtBtnAcc={TEXT_DEF.text0007}
              actionNext={() => {
                setIsShowModalSuccess(false);
              }}
              close={() => {
                setIsShowModalSuccess(false);
              }}
            />
          )}
        </>
      )
        : (
          <ListHis />
        )

      }

    </>
  );
};

export default SubWallet;
