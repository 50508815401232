import {
  Button,
  DatePicker,
  Form,
  Input,
  Pagination,
  Radio,
  Spin,
  Table,
} from "antd";
import { downloadExcel } from "react-export-table-to-excel";
import React, { useEffect, useState } from "react";
import { TEXT_DEF } from "../../constant/text";
import API, { wallet } from "../../network/api";
import moment from "moment";
import FormRequired from "./formRequired";
import ModalCommon from "../../components/Modal";
import MainWalletHistory from "./main-wallet-history";

const ManagerWallet = () => {
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");
  const auth = JSON.parse(localStorage.getItem("auth"));
  const [form] = Form.useForm();

  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [indexTab, setIndexTab] = useState(0);
  const [pageTab1, setPageTab1] = useState(1);
  const [dataFilterTab1, setDataFilterTab1] = useState();
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecordTab1, setTotalRecordTab1] = useState(0);
  const [sumAvaliableBalance, setSumAvaliableBalance] = useState();

  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [itemRecord, setItemRecord] = useState();
  const [key, setKey] = useState();

  const locale = {
    emptyText: TEXT_DEF.text0035,
  };

  useEffect(() => {
    getDataTab1();
  }, [indexTab, pageTab1, dataFilterTab1]);

  useEffect(() => {
    if (
      auth?.role === "supper" ||
      (auth?.role === "admin" &&
        roleArr.some((el) => el === "wallet_view_update"))
    ) {
      return setIndexTab(0);
    } else if (
      auth?.role === "supper" ||
      (auth?.role === "admin" &&
        roleArr.some(
          (el) => el === "wallet_view" || el === "wallet_view_update"
        ))
    ) {
      return setIndexTab(1);
    } else if (
      auth?.role === "supper" ||
      (auth?.role === "admin" &&
        roleArr.some((el) => el === "sub_wallet_update"))
    ) {
      return setIndexTab(2);
    } else if (
      auth?.role === "supper" ||
      (auth?.role === "admin" &&
        roleArr.some(
          (el) => el === "sub_wallet_update" || el === "sub_wallet_view"
        ))
    ) {
      return setIndexTab(3);
    } else if (
      auth?.role === "supper" ||
      (auth?.role === "admin" && roleArr.some((el) => el === "request_top_up"))
    ) {
      return setIndexTab(4);
    }
  }, []);

  const getDataTab1 = async () => {
    setLoading(true);
    let kw = dataFilterTab1
      ? encodeURIComponent(JSON.stringify(dataFilterTab1))
      : null;

    let URL = `${wallet}/all?take=10&skip=${pageTab1}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);

    const result = listData.data.data.result.wallets[0];
    setSumAvaliableBalance(
      Number(listData?.data?.data?.result.sumAvaliableBalance.sum)
    );
    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          key: item?.id,
          username: item.user?.username,
          walletCode: item?.walletCode,
          totalAvailableBalance: +item?.totalAvailableBalance,
          availableBalance: +item?.availableBalance,
          totalUsedAmount: +item?.totalUsedAmount,
          totalCoinSubWallet: +item.totalBalance - +item.balance,
          totalEarn: +item?.totalEarn,
          totalRevenue: +item?.totalRevenue,
          isBlock: item.isBlock,
          createdAt: item.createdAt
            ? moment(item?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
        };

        dataCV.push(itemNew);
      });
      setDataList(dataCV);
      setTotalRecordTab1(listData.data.data.result.wallets[1]);
    } else {
      setDataList([]);
    }
    setLoading(false);
  };

  const exportDataTab1 = async () => {
    setLoading(true);
    let kw = dataFilterTab1
      ? encodeURIComponent(JSON.stringify(dataFilterTab1))
      : null;

    let URL = `${wallet}/all?take=1000000&skip=${pageTab1}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);

    const result = listData.data.data.result.wallets[0];
    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          username: item.user?.username,
          walletCode: item?.walletCode,
          totalAvailableBalance: +item?.totalAvailableBalance,
          availableBalance: +item?.availableBalance,
          totalCoinSubWallet: +item.totalBalance - +item.balance,
          totalUsedAmount: +item?.totalUsedAmount,
          totalEarn: +item?.totalEarn,
          totalRevenue: +item?.totalRevenue,
          createdAt: item.createdAt
            ? moment(item?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
        };
        dataCV.push(itemNew);
      });
      const header = [
        TEXT_DEF.text0002,
        TEXT_DEF.code_wallet,
        TEXT_DEF.totalAvailableBalance,
        TEXT_DEF.availableBalance,
        TEXT_DEF.totalCoinSubWallet,
        TEXT_DEF.totalUsedAmount,
        TEXT_DEF.totalBalance,
        TEXT_DEF.totalRevenue,
        TEXT_DEF.dayBy,
      ];

      downloadExcel({
        fileName: "Danh sách ví chính",
        sheet: "Sheet 1",
        tablePayload: {
          header: header,
          body: dataCV,
        },
      });
    }
    setLoading(false);
  };

  const onChangeTab = (e) => {
    setIndexTab(e.target.value);
    setPageTab1(1);
    setDateFrom(null);
    setDateTo(null);
    form.setFieldValue("usernameFilter", undefined);
    form.setFieldValue("dateFrom", undefined);
    form.setFieldValue("dateTo", undefined);
  };

  const onSearch = () => {
    let params = {};

    if (form.getFieldValue("usernameFilter")) {
      params.username = form
        .getFieldValue("usernameFilter")
        .trim()
        .toLowerCase();
    }

    if (dateFrom) {
      params.startDate = moment(dateFrom).format("YYYY-MM-DD");
      if (!dateTo) params.endDate = "2125-12-31";
    }
    if (dateTo) {
      params.endDate = moment(dateTo).format("YYYY-MM-DD");
      if (!dateFrom) params.endDate = "2023-01-01";
    }

    setDataFilterTab1(params ? params : undefined);
    setPageTab1(1);
  };

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).startOf("day").format('yyyy-MM-DD'));
      else setDateTo(moment(e.$d).endOf("day").format('yyyy-MM-DD'));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
  };

  const onChangePage = (page) => {
    setPageTab1(page);
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const handleShowModal = (item, key) => {
    setIsShowModalConfirm(true);
    setItemRecord(item);
    setKey(key);
  };

  const handleBlockAndUnBlock = async () => {
    try {
      setIsShowModalSuccess(true);
      const body = {
        isBlock: key === "block" ? true : false,
      };
      await API.patch(`${wallet}/${itemRecord.key}`, body);
      await getDataTab1();
    } catch (err) {
      console.log(err);
    } finally {
      setIsShowModalConfirm(false);
    }
  };

  const head_table = [
    {
      title: TEXT_DEF.text0002,
      key: "username",
      dataIndex: "username",
    },
    {
      title: TEXT_DEF.code_wallet,
      key: "walletCode",
      dataIndex: "walletCode",
    },
    {
      title: TEXT_DEF.totalAvailableBalance,
      key: "totalAvailableBalance",
      dataIndex: "totalAvailableBalance",
    },
    {
      title: TEXT_DEF.availableBalance,
      key: "availableBalance",
      dataIndex: "availableBalance",
    },
    {
      title: TEXT_DEF.totalCoinSubWallet,
      key: "totalCoinSubWallet",
      dataIndex: "totalCoinSubWallet",
    },
    {
      title: TEXT_DEF.totalUsedAmount,
      key: "totalUsedAmount",
      dataIndex: "totalUsedAmount",
    },
    {
      title: TEXT_DEF.totalBalance,
      key: "totalEarn",
      dataIndex: "totalEarn",
    },
    {
      title: TEXT_DEF.totalRevenue,
      key: "totalRevenue",
      dataIndex: "totalRevenue",
    },

    {
      title: TEXT_DEF.dayBy,
      key: "createdAt",
      dataIndex: "createdAt",
    },
    // {
    //   title: "Hành động",
    //   key: "action",
    //   render: (text, record) => (
    //     <div className="flex items-center justify-center">
    //       {record.isBlock ? (
    //         <Button
    //           type="danger"
    //           onClick={() => handleShowModal(record, "unblock")}
    //         >
    //           unBlock
    //         </Button>
    //       ) : (
    //         <Button
    //           type="danger"
    //           onClick={() => handleShowModal(record, "block")}
    //         >
    //           Block
    //         </Button>
    //       )}
    //     </div>
    //   ),
    // },
  ];

  const rowClassName = (record) => {
    return record.isBlock && "opacity-50 bg-black/10";
  };

  const renderTab1 = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="usernameFilter" label={TEXT_DEF.text0002}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0032}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrAfter(dateTo && moment(dateTo).add(1, "d"))
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0033}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d || d.isSameOrBefore(dateFrom ? dateFrom : null)
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0031}
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                onClick={exportDataTab1}
                htmlType="button"
                type="primary"
              >
                {TEXT_DEF.text0050}
              </Button>
            </div>
          </Form>
        </div>
        <div className="flex mt-4 items-center">
          <div>{TEXT_DEF.sumAvaliableBalance}:</div>
          <div className="font-bold ml-4">{sumAvaliableBalance}</div>
        </div>
        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <Table
              rowClassName={rowClassName}
              key="dataHis"
              locale={locale}
              columns={head_table}
              dataSource={dataList}
              pagination={false}
              bordered
              style={{ width: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 15,
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              current={pageTab1}
              total={totalRecordTab1}
              onChange={onChangePage}
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={showTotal}
            />
          </div>
        </Spin>
      </>
    );
  };

  return (
    <div className="col-12">
      <div
        className="col-12"
        style={{
          borderBottom: "1px solid #CCC",
          paddingBottom: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Radio.Group onChange={onChangeTab}>
          {auth?.role === "supper" ||
            (auth?.role === "admin" &&
              roleArr.some((el) => el === "wallet_view")) ? (
            <Radio.Button value={0}>{TEXT_DEF.main_wallet}</Radio.Button>
          ) : null}
          {auth?.role === "supper" ||
            (auth?.role === "admin" &&
              roleArr.some(
                (el) => el === "wallet_view" || el === "wallet_view_update"
              )) ? (
            <Radio.Button value={1}>{TEXT_DEF.main_wallet_his}</Radio.Button>
          ) : null}
          {auth?.role === "supper" ||
            (auth?.role === "admin" &&
              roleArr.some((el) => el === "request_top_up")) ? (
            <Radio.Button value={5}>{TEXT_DEF.action}</Radio.Button>
          ) : null}
        </Radio.Group>
      </div>
      {indexTab === 0 ? (
        renderTab1()
      ) : indexTab === 1 ? (
        <MainWalletHistory />
      ) : (
        <FormRequired />
      )}
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text_modal_confirm}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0023}
          actionNext={handleBlockAndUnBlock}
          close={() => setIsShowModalConfirm(false)}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text_modal_success}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </div>
  );
};

export default ManagerWallet;
