import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Input, Button, DatePicker, Table, Pagination, Spin } from "antd";
import API, { TranSaction } from "../../network/api";
import moment from "moment";
import "moment-timezone";
import { downloadExcel } from "react-export-table-to-excel";
import { TEXT_DEF } from "../../constant/text";
import { HEAD_TABLE, methods, transtype } from "../../constant/generate";

const GrowHistory = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();

  const [pageTab1, setPageTab1] = useState(1);
  const [totalRecordTab1, setTotalRecordTab1] = useState(0);
  const [loading, setLoading] = useState(false);
  const [indexTab, setIndexTab] = useState(0);
  const [dataFilterTab1, setDataFilterTab1] = useState();
  const [dataList, setDataList] = useState([]);

  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  const locale = {
    emptyText: TEXT_DEF.text0035,
  };

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    getDataTab1();
  }, [pageTab1, dataFilterTab1]);

  const getDataTab1 = async () => {
    setLoading(true);
    let kw = dataFilterTab1
      ? encodeURIComponent(JSON.stringify(dataFilterTab1))
      : null;

    let URL = `${TranSaction}/all?take=10&skip=${pageTab1}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);

    const result = listData.data?.data.result[0];
    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          key: item?.id,
          username: item.username,
          walletCode: item?.walletCode,
          subWalletCode: item?.subWalletCode,
          amount: +item?.amount,
          deposit: +item?.deposit,
          revenue: +item?.revenue,
          rate: item?.rate,
          ft: item?.ft,
          transRef1: item?.transRef1,
          transType: transtype[item?.transType],
          method: methods[item.method],
          note: item.note,
          status: item.status,
          apptoveNote: item.apptoveNote,
          errorReason: item.errorReason,
          createdAt: item.createdAt
            ? moment(item?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,

          createdBy: item?.createdBy,
        };

        dataCV.push(itemNew);
      });
      setDataList(dataCV);
      setTotalRecordTab1(listData.data?.data?.result[1]);
    } else {
      setDataList([]);
    }
    setLoading(false);
  };

  const exportDataTab1 = async () => {
    setLoading(true);
    let kw = dataFilterTab1
      ? encodeURIComponent(JSON.stringify(dataFilterTab1))
      : null;

    let URL = `${TranSaction}/all?take=1000000&skip=${pageTab1}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);

    const result = listData.data?.data?.result[0];
    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          username: item.username,
          walletCode: item?.walletCode,
          subWalletCode: item?.subWalletCode,
          amount: +item?.amount,
          deposit: +item?.deposit,
          revenue: +item?.revenue,
          rate: item?.rate,
          ft: item?.ft,
          transRef1: item?.transRef1,
          transType: transtype[item?.transType],
          method: methods[item.method],
          status: item.status,
          note: item.note,
          errorReason: item.errorReason,
          createdAt: item.createdAt
            ? moment(item?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          createdBy: item?.createdBy,
        };
        dataCV.push(itemNew);
      });
      const header = [
        TEXT_DEF.text0002,
        TEXT_DEF.code_wallet,
        TEXT_DEF.code_wallet_sub,
        TEXT_DEF.number_coin,
        TEXT_DEF.deposit_money,
        TEXT_DEF.revenue,
        TEXT_DEF.rate,
        TEXT_DEF.ft,
        TEXT_DEF.transRef1,
        TEXT_DEF.transType,
        TEXT_DEF.method,
        TEXT_DEF.status,
        TEXT_DEF.note,
        TEXT_DEF.errorReason,
        TEXT_DEF.time_transition,
        TEXT_DEF.createdBy,
      ];

      downloadExcel({
        fileName: "Lịch sử giao dịch",
        sheet: "Sheet 1",
        tablePayload: {
          header: header,
          body: dataCV,
        },
      });
    }
    setLoading(false);
  };

  const onSearch = () => {
    let params = {};

    if (form.getFieldValue("usernameFilter")) {
      params.username = form
        .getFieldValue("usernameFilter")
        .trim()
        .toLowerCase();
    }

    if (form.getFieldValue("codeFilter")) {
      params.ft = form.getFieldValue("codeFilter").trim().toLowerCase();
    }

    if (dateFrom) {
      params.startDate = moment(dateFrom).format("YYYY-MM-DD");
      if (!dateTo) params.endDate = "2125-12-31";
    }
    if (dateTo) {
      params.endDate = moment(dateTo).format("YYYY-MM-DD");
      if (!dateFrom) params.endDate = "2023-01-01";
    }

    setDataFilterTab1(params ? params : undefined);
    setPageTab1(1);
  };

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).startOf("day").format('yyyy-MM-DD'));
      else setDateTo(moment(e.$d).endOf("day").format('yyyy-MM-DD'));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
  };

  const onChangePage = (page) => {
    setPageTab1(page);
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const renderTab1 = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="usernameFilter" label={TEXT_DEF.text0002}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0032}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrAfter(
                        dateTo ? moment(dateTo).add(1, "d") : moment()
                      )
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0033}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrBefore(dateFrom ? dateFrom : null) ||
                      d.isSameOrAfter(moment())
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-12">
              <div className="col-4">
                <Form.Item name="codeFilter" label={TEXT_DEF.ft}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0031}
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                onClick={exportDataTab1}
                htmlType="button"
                type="primary"
              >
                {TEXT_DEF.text0050}
              </Button>
            </div>
          </Form>
        </div>
        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <Table
              key="dataHis"
              locale={locale}
              columns={HEAD_TABLE}
              dataSource={dataList}
              pagination={false}
              bordered
              style={{ width: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 15,
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              current={pageTab1}
              total={totalRecordTab1}
              onChange={onChangePage}
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={showTotal}
            />
          </div>
        </Spin>
      </>
    );
  };

  return (
    <div className="col-12">
      <div
        className="col-12"
        style={{
          borderBottom: "1px solid #CCC",
          paddingBottom: "2rem",
          marginBottom: "2rem",
        }}
      ></div>

      {renderTab1()}
    </div>
  );
};

export default GrowHistory;
