import { Button, Form, Input, Select, message, AutoComplete } from "antd";
import React, { useEffect, useState } from "react";
import { TEXT_DEF } from "../../../constant/text";
import TextArea from "antd/lib/input/TextArea";
import API, { TranSaction } from "../../../network/api";
import { STATUS_COINS } from "../../../constant/generate";
import ModalCommon from "../../../components/Modal";

const FormRequired = () => {
  const [form] = Form.useForm();

  const [dataOption, setDataOption] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [textMessage, setTextMessage] = useState();

  const [userOptions, setUserOptions] = useState(dataOption);
  const [formData, setFormData] = useState({
    username: "",
    transRef1: "unique",
    amount: 1,
    note: "",
  });

  useEffect(() => {
    getDataUser();
  }, []);

  const getDataUser = async () => {
    try {
      const res = await API.get(
        `user/all?take=100&skip=1&order=DESC&keyword=null`
      );
      const data = res.data.data.result[0];
      setDataOption(
        data.map((item) => {
          return {
            label: item.username,
            value: item.username,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleShowPopup = () => {
    setIsShowModalConfirm(true);
  };

  const handleSubmit = async () => {
    try {
      setIsShowModalSuccess(true);
      setIsShowModalConfirm(false);
      const body = {
        ...formData,
        username: form.getFieldsValue("username").username,
        amount: +formData.amount,
      };
      if (form.getFieldsValue("selectField").selectField === 1) {
        const res = await API.post(`${TranSaction}/request/plus`, body);
        if (res.data.data.code !== 20001) {
          setTextMessage("Tạo yêu cầu không thành công!");
        } else {
          setTextMessage("Tạo yêu cầu thành công!");
        }
      } else {
        const res = await API.post(`${TranSaction}/request/minus`, body);
        if (res.data.data.code !== 20001) {
          setTextMessage("Tạo yêu cầu không thành công!");
        } else {
          setTextMessage("Tạo yêu cầu thành công!");
        }
      }
      setFormData({
        username: "",
        transRef1: "unique",
        amount: 1,
        note: "",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      username: value,
    }));
  };

  const handleFindUser = (value) => {
    if (!value) {
      setUserOptions(dataOption)
    } else {
      setUserOptions(dataOption.filter(item => (item.value.includes(value))))
    }
  }

  return (
    <div className="col-12">
      <div className="flex justify-center">
        <div className="flex flex-col justify-center">
          <div className="border-b flex justify-center">
            <h1>Form tạo yêu cầu</h1>
          </div>
          <Form
            form={form}
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 14,
            }}
            layout="horizontal"
            style={{
              maxWidth: 800,
              width: 500,
            }}
            initialValues={{ selectField: 1 }}
            onFinish={handleShowPopup}
            className="mt-4 flex flex-col w-full"
          >
            <Form.Item label="Yêu cầu" name="selectField">
              <Select
                options={STATUS_COINS}
                style={{ marginLeft: "1rem" }}
              // onChange={handleChangeCoind}
              />
            </Form.Item>
            <Form.Item
              label="Tên user"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn 1 user",
                },
              ]}
            >
              <AutoComplete
                options={userOptions}
                onChange={handleChange}
                placeholder=""
              >
                <Input onChange={(e) => handleFindUser(e.target.value)} style={{ marginLeft: "1rem" }} />
              </AutoComplete>
              {/* <Select
                options={dataOption}
                style={{ marginLeft: "1rem" }}
                onChange={handleChange}
              /> */}
            </Form.Item>
            <Form.Item label={TEXT_DEF.coins_amount}>
              <Input
                type="number"
                value={+formData.amount}
                min={1}
                name="amount"
                style={{ marginLeft: "1rem" }}
                onChange={(e) => handleChangeInput(e)}
              />
            </Form.Item>
            <Form.Item label={TEXT_DEF.note_desc}>
              <TextArea
                name="note"
                value={formData.note}
                style={{ marginLeft: "1rem" }}
                onChange={(e) => handleChangeInput(e)}
              />
            </Form.Item>
            <Form.Item className="flex items-center justify-center mt-2">
              <Button type="primary" htmlType="submit">
                Xác nhận
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.confirm_request}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0023}
          actionNext={handleSubmit}
          close={() => setIsShowModalConfirm(false)}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={textMessage}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </div>
  );
};

export default FormRequired;
