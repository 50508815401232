import React from "react";
import { TEXT_DEF } from "../../../constant/text";
import { Button, Switch } from "antd";

const SettingDeposit = ({
  auth,
  roleArr,
  course1,
  course2,
  course3,
  setCourse1,
  setCourse2,
  setCourse3,
  onHandleShowModal,
}) => {
  return (
    <div className="col-12">
      <div className="flex flex-col w-full">
        <div className="flex w-full">
          <div className="flex flex-col w-full">
            <div className="flex w-full items-center">
              <div className="flex w-[10%] justify-end">
                {TEXT_DEF.text_course}:
              </div>
              <div className="flex mx-10">
                <Switch
                  checkedChildren="Bật"
                  unCheckedChildren="Tắt"
                  checked={course1?.value}
                  onChange={(e) =>
                    setCourse1((prev) => ({
                      ...prev,
                      value: e,
                    }))
                  }
                  style={{ width: 60, marginTop: "5px" }}
                />
              </div>
              {auth?.role === "supper" ||
                (auth?.role === "admin" &&
                  roleArr.some((el) => el === "system_config_edit")) ? (
                <div>
                  <Button
                    type="primary"
                    onClick={() => onHandleShowModal(course1.id, "course1")}
                  >
                    {TEXT_DEF.text0012}
                  </Button>
                </div>
              ) : null}
            </div>
            <div className="flex w-full items-center mt-5">
              <div className="flex w-[10%] justify-end">
                {TEXT_DEF.text_course_1}:
              </div>
              <div className="flex mx-10">
                <Switch
                  checkedChildren="Bật"
                  unCheckedChildren="Tắt"
                  checked={course2?.value}
                  onChange={(e) =>
                    setCourse2((prev) => ({
                      ...prev,
                      value: e,
                    }))
                  }
                  style={{ width: 60, marginTop: "5px" }}
                />
              </div>
              {auth?.role === "supper" ||
                (auth?.role === "admin" &&
                  roleArr.some((el) => el === "system_config_edit")) ? (
                <div>
                  <Button
                    type="primary"
                    onClick={() => onHandleShowModal(course2.id, "course2")}
                  >
                    {TEXT_DEF.text0012}
                  </Button>
                </div>
              ) : null}
            </div>
            <div className="flex w-full items-center mt-5">
              <div className="flex w-[10%] justify-end">
                {TEXT_DEF.text_course_2}:
              </div>
              <div className="flex mx-10">
                <Switch
                  checkedChildren="Bật"
                  unCheckedChildren="Tắt"
                  checked={course3?.value}
                  onChange={(e) =>
                    setCourse3((prev) => ({
                      ...prev,
                      value: e,
                    }))
                  }
                  style={{ width: 60, marginTop: "5px" }}
                />
              </div>
              {auth?.role === "supper" ||
                (auth?.role === "admin" &&
                  roleArr.some((el) => el === "system_config_edit")) ? (
                <div>
                  <Button
                    type="primary"
                    onClick={() => onHandleShowModal(course3.id, "course3")}
                  >
                    {TEXT_DEF.text0012}
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingDeposit;
