import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import { Radio } from "antd";
import API, { COMMON, Ticket3 } from "../../network/api";
import "moment-timezone";
import ModalCommon from "../../components/Modal";
import { TEXT_DEF } from "../../constant/text";

import SettingRule from "./setting-rule";
import ManagerPercent from "./setting-precent";
import SettingDeposit from "./settingDeposit";

const SettingSys = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  const [indexTab, setIndexTab] = useState(0);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [rule, setRule] = useState();
  const [percent, setPercent] = useState();
  const [percentChange, setPercentChange] = useState();
  const [course1, setCourse1] = useState();
  const [course2, setCourse2] = useState();
  const [course3, setCourse3] = useState();

  const [key, setKey] = useState();
  const [idField, setIdField] = useState();

  useEffect(() => {
    getdataSystem();
  }, []);

  const getdataSystem = async () => {
    try {
      const res = await API.get(
        `${COMMON}/all?take=1000&skip=1&order=DESC&keyword=null`
      );
      const data = res.data.data.result[0];
      if (data && res.data.data.message === "List successfully") {
        setRule(
          data.find(
            (el) => el.module === "AWARD_CONGUL" && el.item === "award_congul"
          )
        );
        setPercent(
          data.filter(
            (el) => el.module === "CONDITION" && el.item === "condition_rate"
          )
        );
        setPercentChange(
          data.filter(
            (el) => el.module === "CONDITION" && el.item === "condition_deposit"
          )
        );
        const newData1 = data.find(
          (el) => el.module === "CONDITION" && el.item === "lock_earn"
        );
        setCourse1({
          id: newData1.id,
          value: +newData1.value,
        });
        const newData2 = data.find(
          (el) => el.module === "CONDITION" && el.item === "lock_transfer"
        );
        setCourse2({
          id: newData2.id,
          value: +newData2.value,
        });
        const newData3 = data.find(
          (el) => el.module === "CONDITION" && el.item === "lock_play"
        );
        setCourse3({
          id: newData3.id,
          value: +newData3.value,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeRule = (e) => {
    const { name, value } = e.target;
    setRule((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleShowModal = (id, key) => {
    setIsShowModalConfirm(true);
    setIdField(id);
    setKey(key);
  };

  const handleUpdate = () => {
    switch (key) {
      case "rule":
        return handleSubmit();
      case "percent":
      case "percentChange":
        return handleUpdatePercent();
      case "course1":
        return handleUpdateCourse1();
      case "course2":
        return handleUpdateCourse2();
      case "course3":
        return handleUpdateCourse3();
    }
  };

  const handleSubmit = async () => {
    try {
      setIsShowModalConfirm(false);
      setIsShowModalSuccess(true);
      const body = {
        value: rule.value,
        value1: rule.value1,
      };
      await API.patch(`${COMMON}/${rule.id}`, body);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangePercent = useCallback(
    (e, id) => {
      setPercent(
        percent.map((el) => (el.id === id ? { ...el, value: e } : el))
      );
      setPercentChange(
        percentChange.map((item) =>
          item.id === id ? { ...item, value: e } : item
        )
      );
    },
    [percent, percentChange]
  );

  const handleUpdatePercent = async () => {
    try {
      setIsShowModalConfirm(false);
      setIsShowModalSuccess(true);

      const body = {
        value:
          key === "percentChange"
            ? percentChange.find((el) => el.id === idField).value.toString()
            : percent.find((el) => el.id === idField).value.toString(),
      };
      await API.patch(`${COMMON}/${idField}`, body);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateCourse1 = async () => {
    try {
      setIsShowModalConfirm(false);
      setIsShowModalSuccess(true);
      const body = {
        value: course1.value === true ? "1" : "0",
      };
      await API.patch(`${COMMON}/${idField}`, body);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateCourse2 = async () => {
    try {
      setIsShowModalConfirm(false);
      setIsShowModalSuccess(true);
      const body = {
        value: course2.value === true ? "1" : "0",
      };
      await API.patch(`${COMMON}/${idField}`, body);
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateCourse3 = async () => {
    try {
      setIsShowModalConfirm(false);
      setIsShowModalSuccess(true);
      const body = {
        value: course3.value === true ? "1" : "0",
      };
      await API.patch(`${COMMON}/${idField}`, body);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="col-12">
      <div
        className="col-12"
        style={{
          borderBottom: "1px solid #CCC",
          paddingBottom: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Radio.Group
          value={indexTab}
          onChange={(e) => setIndexTab(e.target.value)}
        >
          <Radio.Button value={0}>{TEXT_DEF.setting_rule}</Radio.Button>
          <Radio.Button value={1}>{TEXT_DEF.text_percent}</Radio.Button>
          <Radio.Button auth={auth} roleArr={roleArr} value={2}>
            {TEXT_DEF.text_course1}
          </Radio.Button>
        </Radio.Group>
      </div>
      {indexTab === 0 ? (
        <SettingRule
          auth={auth}
          roleArr={roleArr}
          rule={rule}
          onHandleChangeInput={handleChangeRule}
          onSubmitRule={handleSubmit}
          onHandleShowModal={handleShowModal}
        />
      ) : indexTab === 1 ? (
        <ManagerPercent
          percent={percent}
          auth={auth}
          roleArr={roleArr}
          percentChange={percentChange}
          onChangePercent={handleChangePercent}
          onHandleShowModal={handleShowModal}
        />
      ) : indexTab === 2 ? (
        <SettingDeposit
          auth={auth}
          roleArr={roleArr}
          course1={course1}
          course2={course2}
          course3={course3}
          setCourse1={setCourse1}
          setCourse2={setCourse2}
          setCourse3={setCourse3}
          onHandleShowModal={handleShowModal}
        />
      ) : null}
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0062}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0023}
          actionNext={handleUpdate}
          close={() => setIsShowModalConfirm(false)}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text0063}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </div>
  );
};

export default SettingSys;
