import { Button, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { TEXT_DEF } from "../../../constant/text";
import { useNavigate, useParams } from "react-router-dom";
import API, { USER } from "../../../network/api";
import ModalCommon from "../../../components/Modal";

const KeyUser = () => {
  const [keyUser, setKeyUser] = useState(null);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getDataDetailUser();
  }, []);

  const getDataDetailUser = async () => {
    try {
      const res = await API.get(`${USER}/${id}`);
      const data = res.data.data.result;
      setKeyUser(data);
    } catch (error) {
      console.log(error);
    }
  };

  const backScreen = () => {
    navigate("/account");
  };

  const handleChangeKey = (e, field) => {
    setKeyUser({
      ...keyUser,
      [field]: e,
    });
  };

  const handleUpdateKey = async () => {
    try {
      setIsShowModalConfirm(false);
      setIsShowModalSuccess(true);
      const body = {
        isBlockEarn: keyUser.isBlockEarn,
        isBlockPlay: keyUser.isBlockPlay,
        isBlockTransfer: keyUser.isBlockTransfer,
      };
      await API.patch(`${USER}/${id}/user-info`, body);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="col-12">
      <Button
        type="default"
        htmlType="button"
        style={{ marginBottom: 30 }}
        onClick={backScreen}
      >
        {TEXT_DEF.text0034}
      </Button>
      <div className="mt-5 col-12 flex">
        <div className="col-2">{TEXT_DEF.text0002}:</div>
        <div className="">{keyUser?.username}</div>
      </div>
      <div className="col-12 mt-5 flex">
        <div className="col-2">{TEXT_DEF.text_course}:</div>
        <div className="col-2">
          <Switch
            checkedChildren="Bật"
            unCheckedChildren="Tắt"
            checked={keyUser?.isBlockEarn}
            style={{ width: 60, marginTop: "5px" }}
            onChange={(e) => handleChangeKey(e, "isBlockEarn")}
          />
        </div>
      </div>
      <div className="col-12 mt-5 flex">
        <div className="col-2">{TEXT_DEF.text_course_2}:</div>
        <div className="col-2">
          <Switch
            checkedChildren="Bật"
            unCheckedChildren="Tắt"
            checked={keyUser?.isBlockPlay}
            style={{ width: 60, marginTop: "5px" }}
            onChange={(e) => handleChangeKey(e, "isBlockPlay")}
          />
        </div>
        <div>
          <Button type="primary" onClick={() => setIsShowModalConfirm(true)}>
            {TEXT_DEF.text0012}
          </Button>
        </div>
      </div>
      <div className="col-12 mt-5 flex">
        <div className="col-2">{TEXT_DEF.text_course_1}:</div>
        <div className="col-2">
          <Switch
            checkedChildren="Bật"
            unCheckedChildren="Tắt"
            checked={keyUser?.isBlockTransfer}
            style={{ width: 60, marginTop: "5px" }}
            onChange={(e) => handleChangeKey(e, "isBlockTransfer")}
          />
        </div>
      </div>
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text_delete_user}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0023}
          actionNext={handleUpdateKey}
          close={() => setIsShowModalConfirm(false)}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text_modal_success}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </div>
  );
};

export default KeyUser;
