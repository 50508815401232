import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { Form, Input, Button } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import API, { LOGIN, USER } from "../../network/api";
import { TEXT_DEF, PERMISSION } from "../../constant/text";

const Login = () => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [errServer, setErrServer] = useState();

  useEffect(() => {
    forceUpdate({});
  }, []);

  console.log(errServer);

  const onLogin = async () => {
    setErrServer(null);

    const params = {
      username: form.getFieldValue("username").trim(),
      password: form.getFieldValue("password").trim(),
      mac: "00:1A:C2:7B:00:47",
      ip: "210.24.209.42",
      is_admin: true,
    };

    await API.post(LOGIN, params)
      .then((response) => {
        if (response.data) {
          const data = response?.data?.data;
          const dataAuth = {
            tokenType: "Bearer",
            accessToken: data?.access_token,
            username: data?.user?.username,
            role: data?.user?.role,
          };
          localStorage.setItem("auth", JSON.stringify(dataAuth));
          getListRoles(dataAuth);
        } else {
          setErrServer(TEXT_DEF.text0006);
        }
      })
      .catch((error) => {
        console.log(error);
        setErrServer(TEXT_DEF.text0006);
      });
  };

  const getListRoles = async (dataAuth) => {
    API.defaults.headers.common["Authorization"] =
      dataAuth.tokenType + " " + dataAuth.accessToken;
    await API.get(`${USER}/user-info`).then((response) => {
      const data = response.data.data?.result;
      if (data) {
        const roles = data?.option;
        localStorage.setItem("roles", roles);
        const arrayRole = roles.split(",");
        if (dataAuth.role === "supper") {
          navigate("/account");
        } else {
          arrayRole?.map((item) => {
            switch (item) {
              case "transaction_view":
                navigate("/growHis");
                break;
              case "system_config_view":
              case "system_config_edit":
                navigate("/settingSys");
                break;
              case "system_config_view":
              case "system_config_edit":
                navigate("/notification");
                break;
              case "system_config_view":
              case "system_config_edit":
                navigate("/game");
                break;
              case "wallet_view":
              case "wallet_view_update":
              case "sub_wallet_view":
              case "sub_wallet_update":
              case "request_top_up":
                navigate("/wallet");
                break;
              case "approve_top_up":
                navigate("/check");
                break;
              default:
                return navigate("/account");
            }
          });
        }

        window.location.reload();
      }
    });
  };

  const clearErr = () => {
    setErrServer(null);
  };

  return (
    <div className="col-12 login-bg">
      <div className="col-4 login-box">
        <div className="lg-title">{TEXT_DEF.text0001}</div>
        <div className="lg-content">
          <Form
            style={{ width: "100%" }}
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={onLogin}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: TEXT_DEF.text0004,
                },
              ]}
              className="col-12"
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={TEXT_DEF.text0002}
                onInput={clearErr}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: TEXT_DEF.text0004,
                },
                {
                  validator: (_, value) => {
                    if (
                      /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*]).{6,32})/.test(
                        value
                      )
                    ) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Some message here");
                    }
                  },
                  message: TEXT_DEF.text0003 + TEXT_DEF.text0005,
                },
              ]}
              className="col-12"
              style={{ paddingTop: 15 }}
            >
              <Input.Password
                min={6}
                maxLength={32}
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder={TEXT_DEF.text0003}
                onInput={clearErr}
              />
            </Form.Item>
            {errServer && (
              <span style={{ color: "#ff4d4f" }}>{TEXT_DEF.text0006}</span>
            )}
            <Form.Item shouldUpdate style={{ width: "100%" }}>
              {() => (
                <div className="lg-btn">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                  >
                    {TEXT_DEF.text0001}
                  </Button>
                </div>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
