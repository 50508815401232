import React from "react";
import { TEXT_DEF } from "../../../constant/text";
import TextArea from "antd/lib/input/TextArea";
import { Button } from "antd";

const SettingRule = ({
  rule,
  auth,
  roleArr,
  onHandleChangeInput,
  onHandleShowModal,
}) => {
  return (
    <div className="col-12">
      <div
        className="col-12 flex-col"
        style={{ display: "flex", marginTop: 30, alignItems: "center" }}
      >
        <div className="col-9">
          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-4">Mô tả 1:</div>
            <div className="col-8" style={{ display: "flex" }}>
              <TextArea
                name="value"
                style={{ width: "100%" }}
                value={rule?.value}
                onChange={(e) => onHandleChangeInput(e)}
                rows={6}
                spellCheck="false"
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        <div className="col-9 mt-5">
          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-4">Mô tả 2:</div>
            <div className="col-8" style={{ display: "flex" }}>
              <TextArea
                name="value1"
                style={{ width: "100%" }}
                value={rule?.value1}
                onChange={(e) => onHandleChangeInput(e)}
                rows={6}
                spellCheck="false"
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        {auth?.role === "supper" ||
        (auth?.role === "admin" &&
          roleArr.some((el) => el === "system_config_edit")) ? (
          <div
            className="col-3 mt-10"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => onHandleShowModal(null, "rule")}
            >
              {TEXT_DEF.text0012}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SettingRule;
