import { Alert, Button, Form, Input, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import { TEXT_DEF } from "../../constant/text";
import API, { COMMON } from "../../network/api";
import ModalCommon from "../../components/Modal";

const SettingNotification = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  const [listNoti, setListNoti] = useState();
  const [statusNoti, setStatusNoti] = useState();
  const [isPending, setIsPending] = useState(false);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [key, setKey] = useState();
  const [idField, setIdField] = useState();
  const [indexField, setIndexField] = useState();

  useEffect(() => {
    getDataNotification();
  }, []);

  const getDataNotification = async () => {
    try {
      const res = await API.get(
        `${COMMON}/all?take=1000&skip=1&order=DESC&keyword=null`
      );
      const data = res.data.data.result[0];
      if (data && res.data.data.message === "List successfully") {
        setListNoti(
          data.filter((el) => el.module === "NOTI" && el.item === "noti")
        );
        const newData = data.find(
          (el) => el.module === "NOTI" && el.item === "traffic_light"
        );
        setStatusNoti({
          id: newData.id,
          value: +newData.value,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowModal = (id, index, key) => {
    setIsShowModalConfirm(true);
    setIdField(id);
    setIndexField(index);
    setKey(key);
  };

  const handleUpdate = () => {
    switch (key) {
      case "noti":
        return handleUpdateNoti();
      case "list":
        return handleAddAndUpdate();
    }
  };

  const handleUpdateNoti = async () => {
    try {
      setIsShowModalConfirm(false);
      setIsShowModalSuccess(true);
      const body = {
        value: statusNoti.value === true ? "1" : "0",
      };
      await API.patch(`${COMMON}/${statusNoti.id}`, body);
    } catch (err) {
      console.log(statusNoti);
    }
  };

  const handleAddData = () => {
    const newData = {
      value: "",
      value1: "",
    };
    setListNoti([...listNoti, newData]);
  };

  const handleDeleteRow = async (id) => {
    try {
      await API.delete(`${COMMON}/${id}`);
      await getDataNotification();
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeInput = (e, index, field) => {
    const updateData = [...listNoti];
    updateData[index] = {
      ...updateData[index],
      [field]: e.target.value,
    };
    setListNoti(updateData);
  };

  const handleAddAndUpdate = async () => {
    if (!listNoti[indexField].value || !listNoti[indexField].value1) {
      setIsShowModalConfirm(false);
      setIsShowModalSuccess(false);
      return message.error("Vui lòng nhập đầy đủ các trường!");
    }

    try {
      setIsShowModalConfirm(false);
      setIsShowModalSuccess(true);
      setIsPending(true);
      const body = {
        item: "noti",
        module: "NOTI",
        value: listNoti[indexField].value,
        value1: listNoti[indexField].value1,
      };
      if (idField) {
        await API.patch(`${COMMON}/${idField}`, body);
      } else {
        await API.post(`${COMMON}/create`, body);
        await getDataNotification();
      }
    } catch (err) {
      console.log(err);
      setIsPending(false);
    } finally {
      setIsPending(false);
    }
  };

  return (
    <div className="col-12">
      <div
        className="col-10 mt-5"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="col-2">{TEXT_DEF.label_notification}</div>
        <Switch
          checkedChildren="Xanh"
          unCheckedChildren="Đỏ"
          checked={statusNoti?.value}
          style={{ width: 60, marginTop: "5px" }}
          onChange={(e) =>
            setStatusNoti((prev) => ({
              ...prev,
              value: e,
            }))
          }
        />
        {auth?.role === "supper" ||
          (auth?.role === "admin" &&
            roleArr.some((el) => el === "system_config_edit")) ? (
          <div style={{ marginLeft: "2rem" }}>
            <Button
              type="primary"
              onClick={() => handleShowModal(null, null, "noti")}
            >
              {TEXT_DEF.text0012}
            </Button>
          </div>
        ) : null}
      </div>
      <div>
        <div>
          {listNoti &&
            listNoti.map((item, index) => (
              <div
                key={index}
                className="col-12 mt-5 flex items-center"
                style={{ display: "flex" }}
              >
                <div className="w-[50%]">
                  <div className="flex flex-col w-full">
                    <div className="flex w-full items-center">
                      <div className="w-[20%] flex justify-end">
                        {TEXT_DEF.title}:
                      </div>
                      <div className="flex w-[70%] ml-8">
                        <Input
                          maxLength={511}
                          spellCheck="false"
                          name="value"
                          value={item.value}
                          onChange={(e) => handleChangeInput(e, index, "value")}
                        />
                      </div>
                    </div>
                    <div className="flex w-full items-center mt-3">
                      <div className="w-[20%] flex justify-end">
                        {TEXT_DEF.desc}:
                      </div>
                      <div className="flex w-[70%] ml-8">
                        <Input
                          maxLength={511}
                          spellCheck="false"
                          name="value1"
                          value={item.value1}
                          onChange={(e) =>
                            handleChangeInput(e, index, "value1")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {auth?.role === "supper" ||
                  (auth?.role === "admin" &&
                    roleArr.some((el) => el === "system_config_edit")) ? (
                  <div className="ml-5 flex flex-col">
                    <Button
                      danger
                      onClick={() => handleDeleteRow(item.id)}
                    >
                      Xóa
                    </Button>
                    <Button
                      type="primary"
                      className=" mt-3"
                      onClick={() => handleShowModal(item.id, index, "list")}
                      disabled={isPending}
                    >
                      Lưu
                    </Button>
                  </div>
                ) : null}
              </div>
            ))}
        </div>
        {listNoti && listNoti.length < 3 && (
          <Button className="mt-5 ml-2" type="primary" onClick={handleAddData}>
            Add
          </Button>
        )}
      </div>
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0062}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0023}
          actionNext={handleUpdate}
          close={() => setIsShowModalConfirm(false)}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text0063}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </div>
  );
};

export default SettingNotification;
