import { Button, DatePicker, Form, Input, Pagination, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { HEAD_TABLE_WALLET } from "../../../constant/generate";
import { TEXT_DEF } from "../../../constant/text";
import API, { wallet } from "../../../network/api";
import moment from "moment";
import { downloadExcel } from "react-export-table-to-excel";

const MainWalletHistory = () => {
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");
  const auth = JSON.parse(localStorage.getItem("auth"));
  const [form] = Form.useForm();

  const [dataFilterTab1, setDataFilterTab1] = useState();
  const [loading, setLoading] = useState(false);
  const [totalRecordTab1, setTotalRecordTab1] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [pageTab1, setPageTab1] = useState(1);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();

  useEffect(() => {
    getDataHistory();
  }, [pageTab1, dataFilterTab1]);

  const getDataHistory = async () => {
    setLoading(true);
    let kw = dataFilterTab1
      ? encodeURIComponent(JSON.stringify(dataFilterTab1))
      : null;

    let URL = `${wallet}/all-history?take=10&skip=${pageTab1}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);
    const result = listData.data.data.result[0];

    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          key: item?.hisId,
          username: item?.user?.username,
          walletCode: item?.walletCode,
          ft: item?.ft,
          description: item?.description,
          balance: +item?.balance,
          availableBalance: +item?.availableBalance,
          holdBalance: +item?.holdBalance,
          totalUsedAmount: +item?.totalUsedAmount,
          totalEarn: +item?.totalEarn,
          totalRevenue: +item?.totalRevenue,
          adjustment: +item.adjustment,
          // minedCoin:
          //   +item?.totalBalance +
          //   +item?.totalUsedAmount -
          //   Math.abs(+item.adjustment),
          createdAt: item.createdAt
            ? moment(item?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          updatedAt: item.updatedAt
            ? moment(item?.updatedAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          updatedBy: item?.updatedBy,
        };
        dataCV.push(itemNew);
      });
      setDataList(dataCV);
      setTotalRecordTab1(listData.data.data.result[1]);
    } else {
      setDataList([]);
    }
    setLoading(false);
  };

  const exportDataHistory = async () => {
    setLoading(true);
    let kw = dataFilterTab1
      ? encodeURIComponent(JSON.stringify(dataFilterTab1))
      : null;

    let URL = `${wallet}/all-history?take=10000&skip=${pageTab1}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);

    const result = listData.data.data.result[0];
    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          username: item?.user?.username,
          createdAt: item.createdAt
            ? moment(item?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          updatedAt: item.updatedAt
            ? moment(item?.updatedAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          walletCode: item?.walletCode,
          ft: item?.ft,
          description: item?.description,
          availableBalance: +item?.availableBalance,
          adjustment: +item.adjustment,
          // minedCoin:
          //   +item?.totalBalance +
          //   +item?.totalUsedAmount -
          //   Math.abs(+item.adjustment),
          totalUsedAmount: +item?.totalUsedAmount,
          totalEarn: +item?.totalEarn,
          totalRevenue: +item?.totalRevenue,
          updatedBy: item?.updatedBy,
        };
        dataCV.push(itemNew);
      });
      const header = [
        TEXT_DEF.text0002,
        TEXT_DEF.dayBy,
        TEXT_DEF.updatedAt,
        TEXT_DEF.code_wallet,
        TEXT_DEF.ft,
        "Mô tả",
        TEXT_DEF.availableBalance,
        TEXT_DEF.adjustment,
        // TEXT_DEF.mainedCoin,
        TEXT_DEF.totalUsedAmount,
        TEXT_DEF.totalBalance,
        TEXT_DEF.totalRevenue,
        TEXT_DEF.user_admin,
      ];

      downloadExcel({
        fileName: "Lịch sử ví chính",
        sheet: "Sheet 1",
        tablePayload: {
          header: header,
          body: dataCV,
        },
      });
    }
    setLoading(false);
  };

  const onSearch = () => {
    let params = {};

    if (form.getFieldValue("usernameFilter")) {
      params.username = form
        .getFieldValue("usernameFilter")
        .trim()
        .toLowerCase();
    }

    if (dateFrom) {
      params.startDate = moment(dateFrom).format("YYYY-MM-DD");
      if (!dateTo) params.endDate = "2125-12-31";
    }
    if (dateTo) {
      params.endDate = moment(dateTo).format("YYYY-MM-DD");
      if (!dateFrom) params.endDate = "2023-01-01";
    }

    setDataFilterTab1(params ? params : undefined);
    setPageTab1(1);
  };

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).startOf("day").format('yyyy-MM-DD'));
      else setDateTo(moment(e.$d).endOf("day").format('yyyy-MM-DD'));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
  };

  const onChangePage = (page) => {
    setPageTab1(page);
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  return (
    <>
      <div className="col-12">
        <Form
          form={form}
          layout="inline"
          autoComplete="off"
          onFinish={() => onSearch()}
        >
          <div className="col-12" style={{ marginBottom: 15, display: "flex" }}>
            <div className="col-4">
              <Form.Item name="usernameFilter" label={TEXT_DEF.text0002}>
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </div>
            <div className="col-4">
              <Form.Item name="dateFrom" label={TEXT_DEF.text0032}>
                <DatePicker
                  placeholder=""
                  onChange={(e) => onChangeDate(e, "from")}
                  format={"DD-MM-YYYY"}
                  style={{ width: "100%" }}
                  disabledTime={(d) =>
                    !d || d.isSameOrAfter(dateTo && moment(dateTo).add(1, "d"))
                  }
                />
              </Form.Item>
            </div>
            <div className="col-4">
              <Form.Item name="dateTo" label={TEXT_DEF.text0033}>
                <DatePicker
                  placeholder=""
                  onChange={(e) => onChangeDate(e, "to")}
                  format={"DD-MM-YYYY"}
                  style={{ width: "100%" }}
                  disabledTime={(d) =>
                    !d || d.isSameOrBefore(dateFrom ? dateFrom : null)
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="payment-search">
            <Button htmlType="submit" type="primary">
              {TEXT_DEF.text0031}
            </Button>
            <Button
              style={{ marginLeft: 20 }}
              onClick={exportDataHistory}
              htmlType="button"
              type="primary"
            >
              {TEXT_DEF.text0050}
            </Button>
          </div>
        </Form>
      </div>
      <Spin spinning={loading} delay={500} size="large">
        <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
          <Table
            key="dataHis"
            columns={HEAD_TABLE_WALLET}
            dataSource={dataList}
            pagination={false}
            bordered
            style={{ width: "100%" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 15,
            justifyContent: "flex-end",
          }}
        >
          <Pagination
            current={pageTab1}
            total={totalRecordTab1}
            onChange={onChangePage}
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={showTotal}
          />
        </div>
      </Spin>
    </>
  );
};

export default MainWalletHistory;
