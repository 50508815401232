import axios from "axios";

export const LOGIN = "auth/login";
export const ROLE = "user/all";
export const LANDS = "Lands";
export const LAND_HIS = "LandHistory";
export const WAREHOUSE_HIS = "Warehouses";
export const SALES_HIS = "SalesHistories";
export const USER = "user";
export const USER_INFO = "user-info";
export const USER_HIS = "user-history";
export const REGISTER = "auth/register";
export const COMMON = "SysConfigs";
export const USER_CONDITION = "UserCondition";
export const AWARD_REGUL = "AwardRegul";
export const LOG = "log";
export const EVENT_TIME = "event-time";

export const Sytems_ALL = "SysConfigs/all";
export const Sytems_ID = "SysConfigs";
export const Ticket3 = "Ticket3";
export const TranSaction = "Transaction";
export const wallet = "Wallet";
export const subWallet = "SubWallet";

export const HISTORY_STAR = {
  getOneStar: "Ticket1/admin-all",
  getTowStar: "Ticket2/admin-all",
  getThreeStar: "Ticket3/admin-all",
  getThreeCheckIn: "Ticket3/checkin-admin-all",
  createTicket3: "Ticket3/create",
  createTicket3Fake: "Ticket3/create-f",
  getFakeAll: "Ticket3/f-all",
  deleteTiket3: "Ticket3",
};

//--------------
const baseURL = "https://api.game-center.fnp476.com/api/v1";

const API = axios.create({
  baseURL: baseURL,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
  },
});

const auth = localStorage.getItem("auth");
if (auth) {
  API.defaults.headers.common["Authorization"] =
    JSON.parse(auth).tokenType + " " + JSON.parse(auth).accessToken;

  API.interceptors.response.use(undefined, (err) => {
    const error = err.response;
    // if error is 401
    if (error.status === 401 || error.status === 403) {
      localStorage.clear();
      window.location.assign("/login");
    }
  });
}

export default API;
