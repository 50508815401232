import { Button, Modal, Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import API, { TranSaction } from "../../../network/api";
import { TEXT_DEF } from "../../../constant/text";
import TextArea from "antd/lib/input/TextArea";
import { STATUS_METHOD } from "../../../constant/generate";

const ListRequest = () => {
  const [dataList, setDataList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemRecord, setItemRecord] = useState();
  const [note, setNote] = useState();
  const [key, setKey] = useState();

  useEffect(() => {
    getDataRequest();
  }, []);

  const getDataRequest = async () => {
    try {
      const res = await API.get(
        `${TranSaction}/all?take=100&skip=1&order=DESC&keyword=%7B%22transType%22%3A%20%22adjustment%22%7D`
      );

      const data = res.data.data.result[0];

      const dataRender = data.map((item) => ({
        key: item.id,
        username: item.username,
        walletCode: item?.walletCode,
        method: STATUS_METHOD[item.method],
        ft: item.ft,
        amount: +item?.amount,
        status: item.status,
        createdBy: item?.createdBy,
        note: item.note,
        approverNote: item.approverNote,
        approvedBy: item.approvedBy,
      }));
      setDataList(dataRender);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEdit = (item, key) => {
    setIsModalOpen(true);
    setItemRecord(item);
    setKey(key);
  };
  const handleDelete = (item, key) => {
    setIsModalOpen(true);
    setItemRecord(item);
    setKey(key);
  };

  const headTable = [
    {
      title: TEXT_DEF.text0002,
      key: "username",
      dataIndex: "username",
    },
    {
      title: TEXT_DEF.code_wallet,
      key: "walletCode",
      dataIndex: "walletCode",
    },
    {
      title: TEXT_DEF.method_wallet,
      key: "method",
      dataIndex: "method",
    },
    {
      title: TEXT_DEF.ft,
      key: "ft",
      dataIndex: "ft",
    },
    {
      title: TEXT_DEF.coins_amount,
      key: "amount",
      dataIndex: "amount",
    },
    {
      title: TEXT_DEF.status,
      key: "status",
      dataIndex: "status",
    },
    {
      title: TEXT_DEF.createdBy,
      key: "createdBy",
      dataIndex: "createdBy",
    },
    {
      title: TEXT_DEF.note_desc,
      key: "note",
      dataIndex: "note",
    },
    {
      title: TEXT_DEF.approverNote,
      key: "approverNote",
      dataIndex: "approverNote",
    },
    {
      title: TEXT_DEF.approvedBy,
      key: "approvedBy",
      dataIndex: "approvedBy",
    },
    {
      title: "Hành động",
      key: "action",
      render: (text, record) => (
        <span className="flex items-center justify-center">
          {record.status === "REQUEST" ? (
            <>
              <Button
                type="primary"
                className="mr-5"
                onClick={() => handleEdit(record, "edit")}
              >
                Đồng ý
              </Button>
              <Button
                danger
                onClick={() => handleDelete(record, "delete")}
              >
                Từ chối
              </Button>
            </>
          ) : (
            <p className="opacity-50">Admin đã duyệt</p>
          )}
        </span>
      ),
    },
  ];

  const handleOk = async () => {
    try {
      const body = {
        username: itemRecord.username,
        amount: itemRecord.amount,
        transRef1: "unique",
        note: note,
      };
      if (key === "edit") {
        await API.post(`${TranSaction}/${itemRecord.key}/approve`, body);
      } else {
        await API.post(`${TranSaction}/${itemRecord.key}/reject`, body);
      }
      setNote("");
      await getDataRequest();
    } catch (err) {
      console.log(err);
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div>
        <Table
          key="dataHis"
          columns={headTable}
          dataSource={dataList}
          pagination={true}
          bordered
          style={{ width: "100%" }}
        />
      </div>
      <Modal
        title="Admin ghi chú"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <TextArea value={note} onChange={(e) => setNote(e.target.value)} />
      </Modal>
    </>
  );
};

export default ListRequest;
