import React, { memo } from "react";
import { TEXT_DEF } from "../../../constant/text";
import { Button, InputNumber } from "antd";

const ManagerPercent = ({
  percent,
  auth,
  roleArr,
  percentChange,
  onChangePercent,

  onHandleShowModal,
}) => {
  return (
    <div className="col-12">
      <div className="flex w-full flex-col">
        {percent &&
          percent.map((item, index) => (
            <div
              className={`flex w-full ${index !== 0 && "mt-4"}`}
              key={item.id}
            >
              <div className="flex flex-col w-full">
                <div className="flex w-[80%]">
                  <div className="w-[20%] flex justify-end">
                    {TEXT_DEF.percent_change}:
                  </div>
                  <div className="w-[20%] mx-4">
                    <InputNumber
                      min={0}
                      value={item.value}
                      onChange={(e) => onChangePercent(e, item.id)}
                    />
                  </div>
                  <div>
                    {auth?.role === "supper" ||
                    (auth?.role === "admin" &&
                      roleArr.some((el) => el === "system_config_edit")) ? (
                      <Button
                        type="primary"
                        onClick={() => onHandleShowModal(item.id, "percent")}
                      >
                        {TEXT_DEF.text0012}
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
        {percentChange &&
          percentChange.map((item, index) => (
            <div className={`flex w-full mt-5`} key={item.id}>
              <div className="flex flex-col w-full">
                <div className="flex w-[80%]">
                  <div className="w-[20%] flex justify-end">
                    {TEXT_DEF.deposit}:
                  </div>
                  <div className="w-[20%] mx-4">
                    <InputNumber
                      min={0}
                      value={item.value}
                      onChange={(e) => onChangePercent(e, item.id)}
                    />
                  </div>
                  {auth?.role === "supper" ||
                  (auth?.role === "admin" &&
                    roleArr.some((el) => el === "system_config_edit")) ? (
                    <div>
                      <Button
                        type="primary"
                        onClick={() =>
                          onHandleShowModal(item.id, "percentChange")
                        }
                      >
                        {TEXT_DEF.text0012}
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default memo(ManagerPercent);
