import React, { useEffect, useState } from "react";
import API, { COMMON } from "../../network/api";
import { TEXT_DEF } from "../../constant/text";
import { Button, Input } from "antd";
import ModalCommon from "../../components/Modal";

const ManagerGame = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  const [listGame, setListGame] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [idField, setIdField] = useState();
  const [indexField, setIndexField] = useState();

  useEffect(() => {
    getDataListGame();
  }, []);

  const getDataListGame = async () => {
    try {
      const res = await API.get(
        `${COMMON}/all?take=100&skip=1&order=DESC&keyword=%7B%22module%22%3A%20%22GAME_CODE%22%7D`
      );
      const data = res.data.data.result[0];
      setListGame(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeInput = (e, index, field) => {

    const newData = listGame.map((item, idex) => {
      if (idex === index) {
        return {
          ...item,
          [field]: e.target.value,
          detail: {
            ...item.detail,
            [field]: e.target.value,
          },
        };
      }
      return item;
    });
    setListGame(newData);
  };

  const handleUpdateGame = async () => {
    try {
      setIsShowModalConfirm(false);
      setIsShowModalSuccess(true);
      const body = {
        module: "GAME_CODE",
        item: listGame[indexField].item,
        detail: {
          image1: listGame[indexField].detail.image1,
          image2: listGame[indexField].detail.image2,
          image3: listGame[indexField].detail.image3,
          deepLink: listGame[indexField].detail.deepLink,
          gameName: listGame[indexField].detail.gameName,
        },
      };
      if (idField) {
        await API.patch(`${COMMON}/${idField}`, body);
        setIdField(null);
        setIndexField(null);
      } else {
        await API.post(`${COMMON}/create`, body);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (id, index, value) => {
    try {
      const body = {
        isDeleted: !value,
      };
      if (id) {
        await API.patch(`${COMMON}/${id}`, body);
        await getDataListGame();
      } else {
        const updateData = [...listGame];
        updateData.splice(index, 1);
        return setListGame(updateData);
      }
    } catch (err) { }
  };

  const handleShowModal = (id, index) => {
    setIsShowModalConfirm(true);
    setIdField(id);
    setIndexField(index);
  };

  const handleAddGame = () => {
    const newData = {
      item: "",
      detail: {
        deepLink: "",
        image1: "",
        image2: "",
        image3: "",
        gameName: "",
      },
    };
    setListGame([...listGame, newData]);
  };

  return (
    <div className="col-12">
      <div
        className="col-12"
        style={{
          borderBottom: "1px solid #CCC",
          marginBottom: "2rem",
        }}
      >
        <h1>List Danh Mục Game</h1>
      </div>
      <div className="col-12">
        <div className="flex w-full">
          <div className="flex flex-col w-full items-center ">
            {listGame &&
              listGame.map((item, index) => (
                <div
                  className={`flex w-full items-center border-b pb-4 ${index !== 0 && "mt-4"
                    }`}
                  key={index}
                >
                  <div className="flex flex-col w-[80%] items-center">
                    <div className="flex w-full items-center">
                      <div className="flex w-[20%] justify-end mr-10">
                        {TEXT_DEF.code_game}:
                      </div>
                      <div className="w-[60%]">
                        <Input
                          value={item.item}
                          name="item"
                          onChange={(e) => handleChangeInput(e, index, "item")}
                          disabled={item.id}
                        />
                      </div>
                    </div>
                    <div className="flex w-full items-center mt-4">
                      <div className="flex w-[20%] justify-end mr-10">
                        {TEXT_DEF.name_game}:
                      </div>
                      <div className="w-[60%]">
                        <Input
                          value={item.detail?.gameName}
                          name="gameName"
                          onChange={(e) =>
                            handleChangeInput(e, index, "gameName")
                          }
                        />
                      </div>
                    </div>
                    <div className="flex w-full items-center mt-4">
                      <div className="flex w-[20%] justify-end mr-10">
                        {TEXT_DEF.icon_game}:
                      </div>
                      <div className="w-[60%]">
                        <Input
                          value={item.detail?.image1}
                          name="image1"
                          onChange={(e) =>
                            handleChangeInput(e, index, "image1")
                          }
                        />
                      </div>
                    </div>
                    <div className="flex w-full items-center mt-4">
                      <div className="flex w-[20%] justify-end mr-10">
                        {TEXT_DEF.icon_name_game}:
                      </div>
                      <div className="w-[60%]">
                        <Input
                          value={item.detail?.image2}
                          name="image2"
                          onChange={(e) =>
                            handleChangeInput(e, index, "image2")
                          }
                        />
                      </div>
                    </div>
                    <div className="flex w-full items-center mt-4">
                      <div className="flex w-[20%] justify-end mr-10">
                        {TEXT_DEF.image_bottom}:
                      </div>
                      <div className="w-[60%]">
                        <Input
                          value={item.detail?.image3}
                          name="image3"
                          onChange={(e) =>
                            handleChangeInput(e, index, "image3")
                          }
                        />
                      </div>
                    </div>
                    <div className="flex w-full items-center mt-4">
                      <div className="flex w-[20%] justify-end mr-10">
                        {TEXT_DEF.link_game}:
                      </div>
                      <div className="w-[60%]">
                        <Input
                          value={item.detail?.deepLink}
                          name="deepLink"
                          onChange={(e) =>
                            handleChangeInput(e, index, "deepLink")
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <Button
                      type="primary"
                      onClick={() => handleShowModal(item.id, index)}
                    >
                      {TEXT_DEF.text0012}
                    </Button>
                    {item.isDeleted && item.id ? (
                      <Button
                        className="mt-4"
                        onClick={() =>
                          handleDelete(item.id, index, item.isDeleted)
                        }
                      >
                        {TEXT_DEF.display}
                      </Button>
                    ) : !item.isDeleted && item.id ? (
                      <Button
                        className="mt-4"
                        onClick={() =>
                          handleDelete(item.id, index, item.isDeleted)
                        }
                      >
                        {TEXT_DEF.hide}
                      </Button>
                    ) : (
                      <Button
                        type="danger mt-4"
                        onClick={() =>
                          handleDelete(item.id, index, item.isDeleted)
                        }
                      >
                        {TEXT_DEF.text0011}
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            <div className="col-10 mt-5">
              <Button type="primary" onClick={handleAddGame}>
                Add
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0062}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0023}
          actionNext={handleUpdateGame}
          close={() => setIsShowModalConfirm(false)}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text0063}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </div>
  );
};

export default ManagerGame;
